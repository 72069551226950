<template>
    <div id="leftnavwrapper" v-show="!home">
      <div id="leftnav" :class="{navon : navtoggle, fixytoggle: fixytoggle}">
        <div style="margin:auto;text-align: center;padding:10px 4px;" v-show="fixytoggle">
            <router-link to="/" v-if="account && account.logo_url">
                <div style="width:160px;margin:auto;height:40px;background: url('https://www.foclarity.com/icons/apple-icon.png');background-size: contain !important;" :style="{backgroundSize : 'contain !important', backgroundPosition : 'center', 'background' : 'url(' + 'https://corl8-files.s3.amazonaws.com/' + account.logo_url + ')', backgroundRepeat : 'no-repeat'}"></div>
                <!-- <img style="border-radius: 1px;height:40px;max-width: 160px;" :src="'https://corl8-files.s3.amazonaws.com/' + account.logo_url" :title="account.account_name" :alt="account.account_name"> -->
            </router-link>
            <router-link to="/" v-else-if="account && account.account_name" v-show="fixytoggle">
                <div style="font-size:18px;">{{ account.account_name | truncate(15, '...') }}</div>
            </router-link>
            <router-link to="/" v-else>
                <div style="width:160px;margin:auto;height:40px;background: url('https://www.foclarity.com/icons/apple-icon.png');background-size: contain !important;" :style="{backgroundSize : 'contain !important', backgroundPosition : 'center', 'background' : 'url(' + require('@/assets/foclarity-logo-black-sm.png') + ')', backgroundRepeat : 'no-repeat'}"></div>
                <!-- <img style="height: 40px;max-width: 160px;" :src="require('@/assets/foclarity-logo-black-sm.png')" alt="Foclarity" /> -->
            </router-link>
        </div>

        <div id="regularleftnav">
          <div class="leftnavlinks">
            <ul>
              <router-link tag="li" v-if="isLoggedIn()" to="/dashboard" v-show="false">
                AI Assistant
                <img :src="require('@/assets/ic_sparkle.png')" width="20" height="20" alt="AI Chat" title="AI Chat" style="cursor:pointer;margin-left:4px;" />
              </router-link>
              <router-link tag="li" v-if="isLoggedIn()" to="/dashboards">Boards</router-link>
              <router-link tag="li" v-if="isLoggedIn()" to="/mission">Mission</router-link>
              <router-link tag="li" v-if="isLoggedIn()" to="/team">Team</router-link>
              <li @click="showVideo = true" v-if="isLoggedIn()" v-show="false">Demo Video</li>
              <router-link tag="li" v-if="!isLoggedIn()" to="/">Home</router-link>
              <router-link tag="li" v-if="!isLoggedIn()" to="/who-we-are">About Us</router-link>
              <router-link tag="li" v-if="!isLoggedIn()" to="/who-we-serve">Training Programs</router-link>
              <router-link tag="li" v-if="!isLoggedIn()" to="/incubators" v-show="false">For Incubators</router-link>
            </ul>
          </div>
        </div>        
      </div>

      <!-- make a set of links that sticks to the bottom of left nav no matter the screen size -->
      <div id="left-settings-nav" :class="{navon : navtoggle, fixytoggle: fixytoggle}">
        <div class="leftnavlinks">
          <ul>
            <li style="height:1px;background:#ccc;"></li>
            <router-link tag="li" v-if="isLoggedIn() && (account && (account.user_id === user.user_id))" :to="{name: 'scorecards', params: {account_id: account.account_id}, query: {r: 'dashboards'}}">+ New Board</router-link>
            <router-link tag="li" v-if="isLoggedIn() && (account && (account.user_id === user.user_id))" :to="{name: 'account', params: {account_id: account.account_id}, query: {r: '/dashboards'}}">Account Settings</router-link>
          </ul>
        </div>
      </div>

      <!-- use the modal component, pass in the prop -->
      <modal v-if="showVideo" @close="showVideo = false">
        <template v-slot:header>
          <h3>Foclarity Demo Video</h3>
        </template>
        <template v-slot:body>
          <iframe style="margin-top:10px;width: 100%; aspect-ratio: 16/9;" src="https://www.youtube.com/embed/Wi9PWCJ_2U0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>        
        </template>
        <template v-slot:footer>
          <p>Foclarity helps your team get focused.</p>
        </template>
      </modal>                

    </div>
  </template>
  
  <script>
  import { isLoggedIn, login, logout } from "../../utils/auth";
  import { accountService, userService } from '../../_services';
  import modal from "@/components/includes/InfoModal"
  
  export default {
    name: "app-leftnav",
    data() {
      return {
        toggle: false,
        navtoggle: true,
        user: "",
        account: "",
        feedback: false,
        fixytoggle: false,
        widetoggle: false,
        home: false,
        showVideo: false
      };
    },
    created () {
      if (this.isLoggedIn()) {
        this.widetoggle = false ;
        this.account = accountService.getActiveAccount()
        userService.getMe().then(data => {
          this.user = data
        });
      }
      if (this.$route.path === '/') {
        this.home = true
      }
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    components: {
      modal
    },
    methods: {
      handleLogin() {
        login();
      },
      handleLogout() {
        logout();
      },
      handleScroll () {
        if (screen.width > 440) {
          if (window.scrollY > 50){
            this.fixytoggle = true
          } else {
            this.fixytoggle = false
          }
        } else {
          if (window.scrollY > 0){
            this.fixytoggle = true
          } else {
            this.fixytoggle = false
          }
        }
      },
      isLoggedIn() {
        return isLoggedIn();
      }    
    }
  };
  </script>
  
  <style scoped lang="scss">
  @import "../../scss/colors";
  #leftnavwrapper {
    // should be the left column inside the content area, under the header
    width:200px;
    flex-shrink: 0;
    background: #fbfbfb;
    border-right:1px solid #eee;
    @media only screen and (max-width: 760px) {
        display: none;
    }

  }
  #leftnav {
    // should be the left nav inside the left column, and should scroll up and down with the page
    position: relative;
    &.fixytoggle {
      position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 1000;
    }
    width: 200px;
    border-right:1px solid #eee;
    // box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    &.navon {
      display: block;
    }
    & > #regularleftnav {
      display: block;
      @media only screen and (max-width: 760px) {
        display: none;
      }
      & > .leftnavlinks {
        & > ul {
          width: auto;
          padding: 0;
          margin: 0;
          & > li {
            display: block;
            text-align: left;
            text-transform: capitalize;
            cursor: pointer;
            margin: 0;
            padding: 0 4px 0 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 2.5em;
            background: #fbfbfb;
            border-bottom: 1px solid #eee;
            color:#0066CC;
            //border-left: 4px solid #ddd ;
            //border-right: 4px solid #ddd ;
            &.router-link-exact-active {
              color: #333;
              background: #eee;
              border-left: 4px solid $color-gray ;
              font-weight: bold;
              // border-right: 4px solid $color-gray ;
            }
            &:hover {
              background: #eee;
              //border-left: 4px solid $color-gray ;
              //border-right: 4px solid $color-accent ;
              //color: #fff ;
            }
          }
        }
      }
    }
    & > #mobileleftnav {
      display: none;
      @media only screen and (max-width: 760px) {
        display: block;
      }
      & > ul {
        width: auto;
        padding: 0;
        margin: 0;
        & > li {
          display: block;
          text-align: left;
          text-transform: capitalize;
          cursor: pointer;
          margin: 0;
          padding: 0 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 2.5em;
          background: #fff;
          border-bottom: 1px solid #eee;
          color:#0066CC;
          //border-left: 4px solid #ddd ;
          //border-right: 4px solid #ddd ;
          &.router-link-exact-active {
            color: #333;
            //border-left: 4px solid $color-gray ;
            //border-right: 4px solid $color-gray ;
          }
          &:hover {
            background: #eee;
            //border-left: 4px solid $color-gray ;
            //border-right: 4px solid $color-accent ;
            //color: #fff ;
          }
        }
      }
    }
}

#left-settings-nav {
  position: absolute;
  z-index: 1000;
  width:200px;
  bottom: 0;
  display: block;
  @media only screen and (max-width: 760px) {
    display: none;
  }
  & > .leftnavlinks {
    & > ul {
      width: auto;
      padding: 0;
      margin: 0;
      & > li {
        display: block;
        text-align: left;
        text-transform: capitalize;
        cursor: pointer;
        margin: 0;
        padding: 0 4px 0 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 2.5em;
        background: #fbfbfb;
        border-bottom: 1px solid #eee;
        border-right: 1px solid #eee;
        color:#0066CC;
        //border-left: 4px solid #ddd ;
        //border-right: 4px solid #ddd ;
        &.router-link-exact-active {
          color: #333;
          // background: #eee;
          //border-left: 4px solid $color-gray ;
          //border-right: 4px solid $color-gray ;
        }
        &:hover {
          background: #eee;
          //border-left: 4px solid $color-gray ;
          //border-right: 4px solid $color-accent ;
          //color: #fff ;
        }
      }
    }
  }
} 
</style>
  